// require('./bootstrap');
// jQuery
window.$ = window.jQuery = require('jquery');

// jQuery Validation
// require('jquery-validation')(window, $);

import "./modules/bootstrap";
import "./modules/sidebar";
import "./modules/theme";
import "./modules/feather";
